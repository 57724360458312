<section class="platform">
    <app-title-section [text]="'Plataforma'"></app-title-section>

    <div class="container mt-5 mb-4 pt-5 pb-5">
        <div class="row content-text">

            <div class="col-12">
                <h2 class="title">Um ambiente digital completo para sua igreja.</h2>

                <h3>Site pensado para igrejas</h3>
                <ul>
                    <li>Sermoões;</li>
                    <li>Downloads;</li>
                    <li>Artigos e publicações;</li>
                    <li>Ministérios;</li>
                    <li>Agenda;</li>
                    <li>Pedidos de oração;</li>
                </ul>
                
                <h3>Ambiente educacional</h3>
                <ul>
                    <li>Disponibilize cursos;</li>
                    <li>Crie avaliações;</li>
                </ul>
                
                <h3></h3>
                <ul>
                    <li>Disponibilize cursos;</li>
                    <li>Textos para leitura</li>
                    <li>Crie avaliações;</li>
                </ul>
                
                <h3>Inteligência e Estratégia</h3>
                <ul>
                    <li>Desenvolva um olhar analítico e estratégico;</li>
                    <li>Conheça o perfil dos membros da igreja, habilidades e aspirações;</li>
                    <li>Saiba o quais os níveis de satisfação e como seus membros estão enchergando a igreja;</li>
                    <li>Saiba como está o clima entre os menbros e quais os cominhos para tornar o clima excelente;</li>
                </ul>
            </div>

        </div>
    </div>

</section>
<header>
    <div class="container">
        <div class="box-header d-flex justify-content-between align-items-center">
            <div tabindex="0" class="box-logo" (click)="scroll('banner')">
                <img src="assets/images/logo-horizontal-site.webp" alt="Logo">
            </div>
            <div class="menu p-3 d-flex justify-content-center" style="cursor: pointer;">
                <div class="p-2 d-flex justify-content-center align-items-center" (click)="toggleMenu()">
                    <mat-icon class="d-xl-none" aria-hidden="false" aria-label="Menu">menu</mat-icon>
                </div>
                <span tabindex="1" class="btn-menu d-none d-xl-block" [class.active]="section == 'enterprise'" (click)="scroll('enterprise')">Empresa</span>
                <span tabindex="2" class="btn-menu d-none d-xl-block" [class.active]="section == 'services'" (click)="scroll('services')">Serviços</span>
                <span tabindex="3" class="btn-menu d-none d-xl-block" [class.active]="section == 'digital-church'" (click)="scroll('digital-church')">Para Igrejas</span>
                <span tabindex="4" class="btn-menu d-none d-xl-block" [class.active]="section == 'contact'" (click)="scroll('contact')">Contato</span>
            </div>
            <div class="box-icons d-none d-xl-block">
                <div class="d-flex justify-content-end">
                    <i tabindex="5" class="fa fa-whatsapp" aria-hidden="true" (click)="open('https://wa.me/5551991503553')"></i>
                    <i tabindex="6" class="fa fa-envelope" aria-hidden="true" (click)="open('mailto:contato@visionar.io')"></i>
                    <i tabindex="7" class="fa fa-linkedin-square" aria-hidden="true" (click)="open('https://www.linkedin.com/company/visionario/')"></i>
                </div>
            </div>
        </div>
    </div>
</header>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { RequestService } from './core/services/request.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'visionar-site';
  displaySidebar = false
  currentUrl:any;

  @ViewChild('drawer', {static:true}) drawer

  constructor(private router: Router, private requestService: RequestService) { }

  data;

  toggleMenu() {
    this.displaySidebar = !this.displaySidebar
  }

  scroll(classId) {
    this.toggleMenu()
    let el = document.getElementsByClassName(classId)
    let pos = el[0].getBoundingClientRect()    
    setTimeout(() => {
      window.scrollTo({top: pos.y + window.pageYOffset -100, behavior: 'smooth'})
    }, 0);
  }

  open(url) {
    window.open(url, "_blank")
  }

  ngOnInit(): void {
    this.requestService.get('/home').pipe(take(1)).subscribe(res => {
      this.data = res;
      console.log(this.data);
    })
  }
  
}

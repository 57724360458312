<section class="intelligence">
    <app-title-section [text]="'Inteligência e Estratégia'"></app-title-section>

    <div class="container mt-5 mb-4 pt-5 pb-5">
        <div class="row content-text">

            <div class="col-12">
                <h2 class="title">Saiba como elaborar as melhores estratégias para sua igreja.</h2>
                <h3>Desenvolva um olhar analítico e estratégico;</h3>
                <h3>Conheça o perfil dos membros da igreja, habilidades e aspirações;</h3>
                <h3>Saiba o quais os níveis de satisfação e como seus membros estão enchergando a igreja;</h3>
                <h3>Saiba como está o clima entre os menbros e quais os cominhos para tornar o clima excelente;</h3>
            </div>

        </div>
    </div>

</section>
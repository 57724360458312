import { AfterContentInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, AfterContentInit {

  banner = -1

  timeTransition = 10000

  constructor() { }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.changeBanner()
    // }, this.timeTransition);
  }

  ngAfterContentInit(){
    this.banner = 0
  }

  changeBanner() {
    if(this.banner == 1) {
      this.banner = 0
    } else {
      this.banner = this.banner + 1
    }

    setTimeout(() => {
      this.changeBanner()
    }, this.timeTransition);
  }

}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment.prod'

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  
  constructor(private http: HttpClient) { }

  get(uri) {
    return this.http.get(environment.cms_url + uri);
  }

  post(uri, body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers: headers };
    
    return this.http.post(environment.cms_url + uri, body, options)
  }

}

<section class="services">
    <app-title-section [text]="'Serviços'"></app-title-section>

    <div class="container mt-5 mb-4 pt-5 pb-5">
        <div class="row">
            <div class="col-md-6 col-lg-3 mb-3" *ngFor="let serv of servicos">
                <app-card-services [icon]="serv.icone" [title]="serv.titulo" [text]="serv.descricao"></app-card-services>
            </div>
        </div>
    </div>

</section>
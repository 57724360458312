<footer>
    <div class="pt-5 pb-5 d-flex justify-content-center">

        <div class="logo">
            <img src="assets/images/logo-horizontal-site.webp" alt="Logo">
        </div>
    </div>
    <div class="stripe p-3 d-flex justify-content-center">
        <span>Todos os direitos reservado á Visionar</span>
    </div>
</footer>
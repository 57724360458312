import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intelligence',
  templateUrl: './intelligence.component.html',
  styleUrls: ['./intelligence.component.scss']
})
export class IntelligenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<section class="digital-church">
    <app-title-section [text]="'Igreja Digital'"></app-title-section>

    <div class="container mt-5 mb-4 pt-5 pb-5">
        <div class="row content-text" color="">

            <div class="col-12 markdown pb-3">
                <markdown ngPreserveWhitespaces>
                    {{ igrejaTexto }}
                </markdown>
            </div>

        </div>

        <mat-tab-group mat-align-tabs="center" dynamicHeight [selectedIndex]="0">

            <ng-container *ngFor="let serv of igrejaServicos">
                <ng-container *ngIf="serv.ativo">
                    <mat-tab [label]="serv.titulo">
                        <div class="row pt-5" style="padding: 0px; margin: 0px;">
                            <div class="col-md-9">
                                <img class="img-folder" [src]="cmsUrl + serv.imagem.url" [alt]="'Imagem Serviço ' + serv.titulo">
                            </div>
                            <div class="col-md-3 content-text mt-5">
                                <div class="markdown">
                                    <markdown ngPreserveWhitespaces>
                                        {{ serv.conteudo }}
                                    </markdown>
                                </div>
                                <!-- <button mat-raised-button color="primary" class="mt-3" (click)="open('')">Saiba mais</button> -->
                            </div>
                        </div>
                    </mat-tab>
                </ng-container>
            </ng-container>

        </mat-tab-group>
    </div>

</section>
<section class="banner">
    <div class="container box-banner">
        <div class="view" *ngIf="banner == 0">
            <div class="carousel-caption">
                <div>
                  <h3 class="title-banner h3-responsive animate__animated animate__fadeInDown">Bem-vindo à Visionar</h3>
                  <p class="text-banner animate__animated animate__fadeIn animate__delay-1s animate__duration-1s">Tecnologia e Inovação</p>
                </div>
            </div>
        </div>
        <!-- <div class="view" *ngIf="banner == 1">
            <div class="carousel-caption">
                <div>
                  <h3 class="title-banner h3-responsive animate__animated animate__fadeInDown animate__delay-0s">Soluções personalizadas</h3>
                  <p class="text-banner animate__animated animate__fadeIn animate__delay-2s animate__duration-1s">Mais que uma empresa, um parceiro</p>
                </div>
            </div>
        </div>
        <div class="view" *ngIf="banner == 2">
            <div class="carousel-caption">
                <div>
                  <h3 class="title-banner h3-responsive animate__animated animate__fadeInDown animate__delay-0s">Inovação Aplicada aos Negócios</h3>
                  <p class="text-banner animate__animated animate__fadeIn animate__delay-2s animate__duration-1s">Alguma coisa</p>
                </div>
            </div>
        </div> -->
    </div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
    <div class="firefly"></div>
</section>

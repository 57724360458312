import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { RequestService } from "src/app/core/services/request.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() phone = '';
  @Input() whatsapp = '';
  @Input() email = '';
  @Input() linkedin = '';

  showMessage = false;
  message = 'Formulário enviado com sucesso.';
  typeMessage = 'Ok';

  loading = false;

  public formulario: FormGroup = new FormGroup({
    'nome': new FormControl(null, [Validators.required, Validators.minLength(3)]), 
    'email': new FormControl(null, [Validators.required, Validators.email]),
    'telefone': new FormControl(null, [Validators.required]),
    'mensagem': new FormControl(null, [Validators.required, Validators.minLength(3)])
  })


  constructor(private requestService: RequestService) { }

  ngOnInit(): void {
  }

  EnviarForm() {
    if (this.formulario.status === "INVALID") {

      this.formulario.get('nome').markAllAsTouched()
      this.formulario.get('email').markAllAsTouched()
      this.formulario.get('telefone').markAllAsTouched()
      this.formulario.get('mensagem').markAllAsTouched()

    } else {
      this.loading = true
      let body:any = {}
      body.nome = this.formulario.controls.nome.value;
      body.email = this.formulario.controls.email.value;
      body.telefone = this.formulario.controls.telefone.value;
      body.mensagem = this.formulario.controls.mensagem.value;
      body.tipo = "Contato Site";

      console.log(body);
      
      
      this.requestService.post('/formularios', body).subscribe(
        (res) => {
          console.log(res);
          let resp:any = res;
          if (resp.id) {
            this.message = "Formulário enviado com sucesso."
            this.typeMessage = 'Ok';
            this.clearFields();
            this.showMessage = true;
            this.loading = false
          } else {
            this.message = "Ops. Algo deu errado, tente enviar o formulário novamente."
            this.typeMessage = 'Error';
            this.showMessage = true;
            this.loading = false
            setTimeout(() => {
              this.showMessage = false;
            }, 10000);
          }

          setTimeout(() => {
            this.showMessage = false;
          }, 10000);
        },
        (error) => {
          this.message = "Ops. Algo deu errado, tente enviar o formulário novamente."
          this.typeMessage = 'Error';
          this.showMessage = true;
          this.loading = false
          setTimeout(() => {
            this.showMessage = false;
          }, 10000);
        }  
      )
    }
  }

  clearFields() {
    this.formulario.controls.nome.setValue('');
    this.formulario.controls.email.setValue('');
    this.formulario.controls.telefone.setValue('');
    this.formulario.controls.mensagem.setValue('');

    this.formulario.controls.nome.markAsUntouched();
    this.formulario.controls.email.markAsUntouched();
    this.formulario.controls.telefone.markAsUntouched();
    this.formulario.controls.mensagem.markAsUntouched();
  }

  open(url) {
    window.open(url, '_blank')
  }
}

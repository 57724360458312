import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod'

@Component({
  selector: 'app-digital-church',
  templateUrl: './digital-church.component.html',
  styleUrls: ['./digital-church.component.scss']
})
export class DigitalChurchComponent implements OnInit {

  @Input() igrejaTexto = '';
  @Input() igrejaServicos = {};
  cmsUrl = '';

  constructor() { }

  ngOnInit(): void {
    this.cmsUrl = environment.cms_url;
  }

  open(url) {
    window.open(url, '_blank')
  }

}

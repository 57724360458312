import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  bannerPos:any;
  enterprisePos:any;
  servicesPos:any;
  digitalChurch:any;
  contactPos:any;

  section = '';
  headerH = 100 + 1;

  @Output() menu = new EventEmitter()

  @HostListener('window:scroll', ['$event']) 
    doSomething(event) {
      this.setSection()
    }

  constructor() { }

  ngOnInit(): void { }

  toggleMenu() {
    this.menu.emit()
  }

  open(url) {
    window.open(url, "_blank")
  }

  scroll(classId) {
    let el = document.getElementsByClassName(classId)
    let pos = el[0].getBoundingClientRect()
    setTimeout(() => {
      window.scrollTo({top: pos.y + window.pageYOffset -100, behavior: 'smooth'})
    }, 0);
  }

  setSection() {
    this.bannerPos = document.getElementsByClassName('banner')[0].getBoundingClientRect();
    this.enterprisePos = document.getElementsByClassName('enterprise')[0].getBoundingClientRect();
    this.servicesPos = document.getElementsByClassName('services')[0].getBoundingClientRect();
    this.digitalChurch = document.getElementsByClassName('digital-church')[0].getBoundingClientRect();
    this.contactPos = document.getElementsByClassName('contact')[0].getBoundingClientRect();    
    
    if (this.bannerPos.y <= this.headerH && this.bannerPos.bottom > this.headerH) {
      this.section = 'banner';
    } else if (this.enterprisePos.y <= this.headerH && this.servicesPos.y > this.headerH) {
      this.section = 'enterprise';
    } else if (this.servicesPos.y <= this.headerH && this.digitalChurch.y > this.headerH) {
      this.section = 'services';
    } else if (this.digitalChurch.y <= this.headerH && this.contactPos.y > this.headerH) {
      this.section = 'digital-church';
    } else if (this.contactPos.y <= this.headerH) {
      this.section = 'contact';
    } else {
      this.section = ''
    }
    
  }

}

<section class="enterprise">
  <app-title-section [text]="'Empresa'"></app-title-section>

  <div class="mt-5 mb-5 pt-5 pb-5 container content-section">
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="mt-3 section-title">Empresa</h2>
          </mat-panel-title>
          <mat-panel-description>
            <!-- Um pouco sobre nós -->
            <!-- <mat-icon>fingerprint</mat-icon> -->
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="mt-4 pt-2 section-text">
          {{ empresa }}
          <!-- <p>
            A Visionar é uma empresa de pesquisa, desenvolvimento e consultoria, criada com a missão de transformar o
            dia a dia das empresas, organizações e igrejas fornecendo soluções tecnológicas, além de ajudá-las a superar
            os desafios e inovar na busca de seus objetivos.
          </p>
          <p>
            Trabalhamos com a projeção, criação e desenvolvimento de software, consultoria em tecnologia e inovação com
            soluções focadas nas necessidades do cliente, oferecendo um atendimento especializado de alto compromisso,
            softwares seguros e tecnologia de ponta, buscando sempre o crescimento e satisfação de nosso cliente.
          </p> -->
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="mt-3 section-title">Visão</h2>
          </mat-panel-title>
          <mat-panel-description>
            <!-- O que buscamos -->
            <!-- <mat-icon>visibility</mat-icon> -->
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="mt-4 pt-2 section-text">
          {{ visao }}
          <!-- <p>
            Utilizar a tecnologia como uma ferramenta para tranformação, avanço e crescimento de nossos clientes, tornando-os visionários em seus respectivos objetivos.
          </p> -->
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="mt-3 section-title">Valores</h2>
          </mat-panel-title>
          <mat-panel-description>
            <!-- O que nos inspira -->
            <!-- <mat-icon>public</mat-icon> -->
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="mt-4 pt-2 section-text">
          {{ valores }}
          <!-- <p>
            Buscamos a ética e valores do Reino de Deus, bem como, tudo que encontramos nas escrituras como nosso guia de conduta.
          </p> -->
        </div>
      </mat-expansion-panel>

    </mat-accordion>
  </div>

</section>
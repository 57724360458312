import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { MarkdownModule } from 'ngx-markdown';

// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';

// PrimeNG
import { SidebarModule } from 'primeng/sidebar';

import { GoogleAnalyticsService } from './core/services/google-analytics.service';

import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { BannerComponent } from './core/components/banner/banner.component';
import { TitleSectionComponent } from './core/components/title-section/title-section.component';
import { CardServicesComponent } from './core/components/card-services/card-services.component';

import { HomeComponent } from './pages/home/home.component';
import { ProjectsComponent } from './pages/home/projects/projects.component';
import { ContactComponent } from './pages/home/contact/contact.component';
import { EnterpriseComponent } from './pages/home/enterprise/enterprise.component';
import { ServicesComponent } from './pages/home/services/services.component';
import { DigitalChurchComponent } from './pages/home/digital-church/digital-church.component';
import { IntelligenceComponent } from './pages/home/intelligence/intelligence.component';
import { PlatformComponent } from './pages/home/platform/platform.component';

import { NotFoundComponent } from './pages/not-found/not-found.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    HomeComponent,
    ProjectsComponent,
    ContactComponent,
    EnterpriseComponent,
    ServicesComponent,
    TitleSectionComponent,
    CardServicesComponent,
    DigitalChurchComponent,
    IntelligenceComponent,
    PlatformComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MarkdownModule.forRoot(),

     // Material
     MatButtonModule,
     MatIconModule,
     MatExpansionModule,
     MatTabsModule,
     MatInputModule,

    //  Prime
    SidebarModule
  ],
  providers: [GoogleAnalyticsService, {provide: 'googleTagManagerId', useValue: 'GTM-MCBZKWB'}],
  bootstrap: [AppComponent]
})
export class AppModule { }

<p-sidebar [(visible)]="displaySidebar">
  <div class="logo-menu-mobile">
    <div class="mt-5">
      <img tabindex="0" (click)="scroll('banner')" src="assets/images/logo-horizontal-site.webp" alt="Logo">
    </div>

    <div class="menu-mobile mt-5 d-flex justify-content-center">
      <div class="d-flex flex-column" *ngIf="displaySidebar">
        <span tabindex="1" class="btn-menu p-3 d-flex justify-content-center animate__animated animate__fadeIn animate__duration-2s animate__delay-1s dalay-enterprise" (click)="scroll('enterprise')">Empresa</span>
        <span tabindex="2" class="btn-menu p-3 d-flex justify-content-center animate__animated animate__fadeIn animate__duration-2s animate__delay-1s dalay-services" (click)="scroll('services')">Serviços</span>
        <span tabindex="3" class="btn-menu p-3 d-flex justify-content-center animate__animated animate__fadeIn animate__duration-2s animate__delay-1s dalay-projects" (click)="scroll('digital-church')">Para Igrejas</span>
        <span tabindex="4" class="btn-menu p-3 d-flex justify-content-center animate__animated animate__fadeIn animate__duration-2s animate__delay-1s dalay-contact" (click)="scroll('contact')">Contato</span>
      </div>
    </div>
    <div class="menu-mobile mt-5 d-flex justify-content-center" *ngIf="displaySidebar">
      <div class="d-flex justify-content-center animate__animated animate__fadeInDown animate__delay-1s animate__duration-2s">
        <i tabindex="5" class="fa fa-whatsapp" aria-hidden="true" (click)="open('https://wa.me/5551991503553')"></i>
        <i tabindex="6" class="fa fa-envelope" aria-hidden="true" (click)="open('mailto:contato@visionar.io')"></i>
        <i tabindex="7" class="fa fa-linkedin-square" aria-hidden="true" (click)="open('https://www.linkedin.com/company/visionario/')"></i>
      </div>
    </div>
  </div>
</p-sidebar>

<app-header (menu)="toggleMenu()"></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
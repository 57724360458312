<section class="contact">
    <app-title-section [text]="'Contato'"></app-title-section>

    <div class="container mt-5 pt-5">
        <div class="row">
            <div class="col-md-6 pb-5 contact-texts">
                <h3 class="title-contacts pb-3">Algumas Formas de Contato</h3>
                <div class="d-flex justify-content-start mt-3">
                    <i class="fa fa-phone icn mr-3" aria-hidden="true"></i>
                    <span>{{ phone }}</span>
                </div>
                <div class="d-flex justify-content-start mt-3">
                    <i class="fa fa-whatsapp icn mr-3" aria-hidden="true"></i>
                    <span>{{ whatsapp }}</span>
                </div>
                <div class="d-flex justify-content-start mt-3">
                    <i class="fa fa-envelope-o icn mr-3" aria-hidden="true"></i>
                    <span>{{ email }}</span>
                </div>
                <div class="d-flex justify-content-start mt-3">
                    <i class="fa fa-linkedin-square icn mr-3" aria-hidden="true"></i>
                    <span>{{ linkedin }}</span>
                </div>
            </div>

            <div class="col-md-6 pb-5">
                <form [formGroup]="formulario" novalidate (ngSubmit)="EnviarForm()">
                    <div class="row">
                      <div class="col-12 mb-2">

                          
                          <div class="form-group">
                            <mat-form-field class="example-full-width">
                                <mat-label>Nome</mat-label>
                                <input matInput formControlName="nome" placeholder="">
                                <mat-hint>Como deseja ser chamado</mat-hint>
                                <mat-error *ngIf="!formulario.get('nome').valid && formulario.get('nome').touched">
                                    Campo obrigatório. Mínimo 3 caractéres.
                                </mat-error>
                            </mat-form-field>                       
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Email</mat-label>
                                    <input matInput formControlName="email" placeholder="">
                                    <mat-hint>Email mais utilizado</mat-hint>
                                    <mat-error *ngIf="!formulario.get('email').valid && formulario.get('email').touched">
                                        Email inválido.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefone</mat-label>
                                    <input matInput formControlName="telefone" placeholder="">
                                    <mat-hint>Se possível, numero com Whatsapp</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Mensagem</mat-label>
                                    <textarea matInput formControlName="mensagem" id="mensagem" rows="4" placeholder=""></textarea>
                                    <mat-hint>Como podemos te ajudar</mat-hint>
                                    <mat-error *ngIf="!formulario.get('mensagem').valid && formulario.get('mensagem').touched">
                                        Campo obrigatório. Mínimo 3 caractéres.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 mb-5">
                        <div class="col-12 d-flex justify-content-between align-items-center">
                            
                            <div class="d-flex">
                                <button class="mr-3" type="submit" mat-raised-button color="primary" [disabled]="!formulario.valid" style="min-width: 120px;">Enviar</button>
                                <div class="spinner-border" *ngIf="loading" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            
                            <div class="message p-3 d-flex justify-content-center align-items-center" *ngIf="showMessage" [ngClass]="{'success': typeMessage === 'Ok', 'danger': typeMessage === 'Error'}">
                                <span class="text">{{ message }}</span>
                            </div>

                        </div>
                    </div>
                </form>

            </div>
        </div>

    </div>

</section>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-services',
  templateUrl: './card-services.component.html',
  styleUrls: ['./card-services.component.scss']
})
export class CardServicesComponent implements OnInit {

  @Input() icon = "important_devices"
  @Input() title = "Título"
  @Input() text = "Texto sobre o título"

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators'
import { RequestService } from "src/app/core/services/request.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  data:any;

  constructor(private requestService: RequestService) { }

  ngOnInit(): void {
    this.requestService.get('/home').pipe(take(1)).subscribe(res => {
      this.data = res;
      console.log(this.data);
    })
  }

}

<app-banner></app-banner>

<ng-container *ngIf="data">
    <app-enterprise [empresa]="data.empresa" [visao]="data.visao" [valores]="data.valores"></app-enterprise>
    
    <app-services [servicos]="data.servicos"></app-services>
    
    <app-digital-church [igrejaTexto]="data.igreja_texto" [igrejaServicos]="data.igreja_servicos"></app-digital-church>
    
    <app-contact 
    [phone]="data.telefone"
    [whatsapp]="data.whatsapp"
    [email]="data.email"
    [linkedin]="data.linkedin" ></app-contact>

</ng-container>

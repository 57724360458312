<section class="projects">
    <app-title-section [text]="'Projetos'"></app-title-section>

    <div class="container mt-5 mb-5 pt-5 pb-5" color="secondary">
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Portal">
                <div class="row pt-5" style="padding: 0px; margin: 0px;">
                    <div class="col-md-6">
                        <img class="img-folder" src="assets/images/projects/crfrs.webp" alt="CRFRS Portal">
                    </div>
                    <div class="col-md-6 content-text mt-5">
                        <h3>Portal CRF/RS</h3>
                        <p>O portal do CRF/RS é uma plataforma completa, com mais de 100 páginas, possui conteudo dinâmico, busca inteligente, o portal transparencia com gerenciador de arquivos e gerenciador de conteudo completo.</p> 
                        <button mat-raised-button color="primary" class="mt-3" (click)="open('https://crfrs.org.br/')">Saiba mais</button>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Site">
                <div class="row pt-5" style="padding: 0px; margin: 0px;">
                    <div class="col-md-6">
                        <img class="img-folder" src="assets/images/projects/viaverde.webp" alt="Site Viaverde">
                    </div>
                    <div class="col-md-6 content-text mt-5">
                        <h3>Site ViaVerde Energia</h3>
                        <p>A ViaVerde usa seu site como um dos principais cartões de visita da empresa, com um gerenciador de conteudo eficiente, é possível realizar alterações a qualquer momento.</p>
                        <button mat-raised-button color="primary" class="mt-3" (click)="open('https://viaverdeenergia.com.br/')">Saiba mais</button>
                    </div>
                </div>
                
            </mat-tab>
            <mat-tab label="Plataforma">
                <div class="row pt-5" style="padding: 0px; margin: 0px;">
                    <div class="col-md-6">
                        <img class="img-folder" src="assets/images/projects/ibdr.webp" alt="Plataforma IBDR">
                    </div>
                    <div class="col-md-6 content-text mt-5">
                        <h3>Plataforma IBDR</h3>
                        <p>A Plataforma do IBDR é utilizada como ambiente educacional, onde os membros do IBDR tem acesso a conteudos e materiais de estudo exclusivos.</p>
                        <button mat-raised-button color="primary" class="mt-3" (click)="open('https://plataforma.ibdr.org.br/')">Saiba mais</button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="App">
                <div class="row pt-5" style="padding: 0px; margin: 0px;">
                    <div class="col-md-6">
                        <img class="img-folder" src="assets/images/projects/sdf.webp" alt="App SDF">
                    </div>
                    <div class="col-md-6 content-text mt-5">
                        <h3>App SDF - CRF/RS</h3> 
                        <p>Aplicativo desenvolvido com a finalidade de gerar uma experiência única no evento SDF, onde o aplicativo foi utilizado para interagir com os participantes durante todo o evento.</p>
                        <button mat-raised-button color="primary" class="mt-3" (click)="open('https://play.google.com/store/apps/details?id=br.org.crfrs.sdf&hl=pt_BR')">Saiba mais</button>    
                    </div>
                </div>
            </mat-tab>
          </mat-tab-group>
    </div>
</section>